<template>
	<v-card elevation="0" class="h-full min-height-screen">
		<v-toolbar flat class="border-b-2 border--secondary mb-2 px-0 py-3 !h-85px">
			<v-toolbar-title>
				<a :href="`${baseUrl}/me/orders?token=${authToken}`" class="text-decoration-none mr-2">
					<v-icon color="primary">mdi-arrow-left</v-icon>
				</a>
				<strong>Order Details</strong>
			</v-toolbar-title>
		</v-toolbar>

		<transition name="fade" mode="out-in">
			<div
				v-if="isLoading"
				class="d-flex align-center justify-center w-full h-full"
				style="height: 400px"
			>
				<loader />
			</div>
			<div v-else-if="hasOrder && !isLoading" class="px-3 py-4">
				<div class="d-flex justify-space-between mb-2 flex-wrap align-center">
					<span class="text-h6 font-weight-bold">{{ `#${order.orderNumber}` }}</span>
					<span>{{ order.orderDate }}</span>
				</div>
				<p :style="orderStatusStyles">{{ order.status }}</p>

				<div class="d-flex align-center flex-wrap">
					<div class="flex-grow-1 mr-3">
						<p class="mb-1 font-weight-bold">Name:</p>
						<p class="text--secondary">{{ order.customerName }}</p>
					</div>
					<div class="flex-grow-1">
						<p class="mb-1 font-weight-bold">Phone:</p>
						<p class="text--secondary">{{ order.mobileNo }}</p>
					</div>
				</div>
				<p class="mb-1 font-weight-bold">Delivered to:</p>
				<p class="text--secondary">{{ order.deliveryAddress }}</p>

				<!-- order products -->
				<div class="mb-4">
					<v-card
						v-for="product in order.orderDetail"
						:key="product.id"
						flat
						elevation="0"
						class="px-3 py-3 rounded border-2 border--secondary mt-4"
					>
						<div class="d-flex justify-space-between align-end pa-2 rounded-small">
							<!--  -->
							<div class="d-flex justify-space-between align-center">
								<div class="">
									<v-avatar size="54" tile v-if="product.imageUrl">
										<v-img
											v-if="product.imageUrl.startsWith('http')"
											class="rounded"
											:lazy-src="product.imageUrl"
											:src="product.imageUrl"
										/>
										<v-img
											v-else
											class="rounded"
											:lazy-src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
											:src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
										/>
									</v-avatar>
									<v-avatar v-else size="54" tile>
										<v-img
											class="rounded"
											lazy-src="/placeholder-image.png"
											src="/placeholder-image.png"
										/>
									</v-avatar>
								</div>

								<div class="ml-2 flex-grow-1">
									<h3 class="text-base mb-2 font-weight-bold leading-normal">
										{{ product.productName }}
									</h3>
									<p class="mb-0 text-sm">
										<span class="text--secondary mr-1">Quantity:</span>
										<strong>{{ product.qtyOrdered }}</strong>
									</p>
								</div>
							</div>
							<div>
								<p class="primary--text font-weight-bold mb-0">
									{{ currencyFormatter.format(product.unitCost) }}
								</p>
							</div>
						</div>
					</v-card>
				</div>

				<div class="mb-16 pb-8">
					<p class="text-h6 font-weight-bold mb-3">Bill Details</p>
					<div class="d-flex align-center justify-space-between">
						<span class="text--secondary">Item Total</span>
						<p class="mb-0 font-weight-bold">
							{{ currencyFormatter.format(order.totalOrderAmountExDC) }}
						</p>
					</div>
					<div class="d-flex align-center justify-space-between mt-2">
						<span class="text--secondary d-inline-flex align-items-center flex-wrap"
							>Delivery Fee&nbsp;
							<strong v-if="order.isDeliveryDiscount && order.couponCode"
								>({{ order.couponCode }}) Applied</strong
							></span
						>
						<p class="mb-0 font-weight-bold">
							+ {{ currencyFormatter.format(order.deliveryCharge) }}
						</p>
					</div>
					<hr class="my-4" />
					<div class="d-flex align-center justify-space-between mt-4 font-weight-bold">
						<p class="mb-0">Order Total</p>
						<p class="mb-0">{{ currencyFormatter.format(order.totalOrderAmount) }}</p>
					</div>
					<div
						v-if="hasOrderSavingAmount"
						class="d-flex align-center justify-space-between font-weight-bold mt-2"
					>
						<p class="mb-0">You Saved</p>
						<p class="mb-0">{{ currencyFormatter.format(order.totalSaving) }}</p>
					</div>
				</div>
			</div>
			<div
				v-else-if="!hasOrder && !isLoading"
				class="d-flex align-center justify-center w-full h-full mt-6"
			>
				<h3>Order not found</h3>
			</div>
			<div v-else class="d-flex align-center justify-center w-full h-full mt-6">
				<h3>Oops!! something went wrong</h3>
			</div>
		</transition>
	</v-card>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import currencyFormatter from '@/utils/currency-formatter'
import { getOrderDetails } from '@/services/order'
import { ORDER_STATUSES } from '@/constants/order-status'
import { IMAGE_BASE_URL } from '@/constants'
import { SUCCESS, NOT_FOUND } from '@/constants/status-code'

export default {
	name: 'MyOrderDetails',
	mixins: [AuthMixin],
	components: {
		Loader,
	},
	data() {
		return {
			currencyFormatter,
			IMAGE_BASE_URL,
			isLoading: false,
			orderId: this.$route.params.id,
			order: null,
		}
	},
	created() {
		this.fetchOrderDetailsById()
	},
	computed: {
		hasOrder() {
			return this.order
		},
		hasOrderSavingAmount() {
			// if saving amount is greater than zero
			return parseFloat(this.order.totalSaving, 10) > 0
		},
		orderStatusStyles() {
			if (!this.order) return {}
			if (this.order.status === ORDER_STATUSES.ORDER_PLACED) return { color: '#1E91CF' }
			if (this.order.status === ORDER_STATUSES.IN_PROGRESS) return { color: '#54B7D3' }
			if (this.order.status === ORDER_STATUSES.DELIVERED) return { color: '#2AA952' }
			if (this.order.status === ORDER_STATUSES.NOT_DELIVERED) return { color: '#FFA902' }
			if (this.order.status === ORDER_STATUSES.CANCELED) return { color: '#FF2525' }
			if (this.order.status === ORDER_STATUSES.PAYMENT_INITIATED) {
				return { color: '#94a3b8' }
			}
			return {}
		},
	},
	methods: {
		async fetchOrderDetailsById() {
			this.isLoading = true
			try {
				const res = await getOrderDetails(this.authToken, { orderId: this.orderId })
				const { data, statusCode } = res.data

				if (statusCode === NOT_FOUND) {
					this.order = null
				}

				if (statusCode === SUCCESS) {
					this.order = data
				}
			} catch (e) {
				console.log('error', e)
			}
			this.isLoading = false
		},
	},
}
</script>
